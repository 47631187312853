import React from "react";
import NavMenuRequestDemo from "../components/NavMenuRequestDemo";

import imageProductMenu from "../images/product_menu.png";
import imageSolutionsMenu from "../images/solutions_menu.png";
import imageMenuResource from "../images/menu_resource.png";
import imageAboutMenu from "../images/about_menu.png";
import imageWorldWide from "../images/worldwide.png";
import imageOnlineCoRoundCorder from "../images/imageonline-co-roundcorner.png";

export const menuItems = [
  {
    name: "entomo",
    component: "entomo",
    title: "entomo",
    description: "perangkat lunak manajemen kinerja modern yang dibuat untuk pekerjaan di masa depan",
    img: imageProductMenu,
    action: {
      name: "lihat semua",
      url: "/id/all-products/entomo/",
    },
    sub: [
      {
        name: "Perform",
        description: "mengukur dan mendorong kinerja setiap hari",
        url: "/id/all-products/entomo/#perform",
      },
      {
        name: "engage",
        description: "mendorong keterlibatan di dalam dan keseluruhan tim",
        url: "/id/all-products/entomo/#engage",
      },
      {
        name: "nudge",
        description: "dorongan dengan personalisasi pencapaian insight pada individu dan tim",
        url: "/id/all-products/entomo/#nudge",
      },
      {
        name: "act",
        description: "mengelola tim yang tersebar dan berlokasi jauh",
        url: "/id/all-products/entomo/#act",
      },
      {
        name: "grow",
        description: "membangun tenaga kerja yang siap menghadapi hari esok",
        url: "/id/all-products/entomo/#grow",
      },
      {
        name: "fitur",
        url: "/id/all-products/pre-configured-editions/",
      },
    ],
  },
  {
    name: "solusi",
    component: "solusi",
    title: "solusi",
    description: "persiapkan organisasi anda untuk masa depan yang ingin anda ciptakan",
    url: "/id/solutions/",
    img: imageSolutionsMenu,
    action: {
      name: "lihat semua",
      url: "/id/all-products/entomo/",
    },
    sub: [
      {
        name: "everyday performance",
        url: "/id/solutions/everyday-performance/",
      },
      {
        name: "everyday insights",
        url: "/id/solutions/everyday-insights/",
      },
      {
        name: "careers tomorrow",
        url: "/id/solutions/careers-tomorrow/",
      },
      {
        name: "first safety",
        url: "/id/solutions/workplace-safety/",
      },
    ],
  },
  {
    name: "inspirasi",
    component: "inspirasi",
    title: "inspirasi",
    description: "wawasan industri, kisah yang menginspirasi, ide-ide inovatif – semuanya berada di satu tempat.",
    img: imageMenuResource,
    action: {
      name: "lihat semua",
      url: "/id/resources",
    },
    sub: [
      {
        name: "blog",
        url: "/id/resources/blog/",
      },
      {
        name: "e buku & laporan resmi",
        url: "/id/resources/whitepaper-e-book/",
      },
      {
        name: "peristiwa & webinar",
        url: "/id/resources/events-webinar/",
      },
      {
        name: "kisah sukses",
        url: "/id/resources/success-stories/",
      },
    ],
  },
  {
    name: "tentang kami",
    component: "tentang kami",
    title: "mengapa entomo?",
    description: "wawasan industri, kisah yang menginspirasi, ide-ide inovatif – semuanya berada di satu tempat.",
    img: imageAboutMenu,
    sub: [
      {
        name: "mengapa entomo",
        url: "/id/about/why-entomo/",
      },
      {
        name: "tim kami",
        url: "/id/about/our-team/",
      },
      {
        name: "komunitas entomo",
        url: "/id/entomo-community/",
      },
      {
        name: "berita & siaran pers",
        url: "/id/news-press-releases/",
      },
    ],
  },
  {
    name: "hubungi kami",
    component: "hubungi kami",
    url: "/id/contact/",
  },
  {
    name: "request demo",
    component: <NavMenuRequestDemo text="ajukan demo" url={"/id/request-demo/"} />,
  },
  {
    name: "regional presense",
    component: <img src={imageWorldWide} alt="worldwide" className="h-6 lg:h-7" />,
    title: "kehadiran regional",
    img: imageOnlineCoRoundCorder,
    sub: [
      {
        name: "Middle East & Africa",
        url: "/en-mea/",
      },
      {
        name: "Global",
        url: "/",
      },
    ],
  },
];
