import React from "react";
import { Link } from "gatsby";
import Container from "../../../utils/Container";
import { regions, innovationCenters, solutions, inspirations } from "../../../constants/footer-id";
import { getCopyright } from "../../../utils";

const Footer = () => {
  return (
    <footer>
      <Container className="max-w-[125rem] mx-aut0 my-0">
        <div className="sm:block lg:flex justify-around  pt-10 ">
          <div className="md:w-full lg:w-2/5">
            <div>
              <h2 className=" font-bold text-2xl">kantor regional</h2>
              <div className="grid md:grid-cols-2 sm:grid-col-1 gap-7 pb-10">
                {regions.map((region) => {
                  return (
                    <div className="text-[#333333] text-base  mt-4 font-extrabold" key={region.id}>
                      <h3>{region.country}</h3>
                      <div className="text-[#4d4d4d] text-sm font-normal">
                        {region.addressLines.map((addressLine) => {
                          return (
                            <p key={addressLine} className="text-[#4d4d4d] text-sm font-normal leading-7">
                              {addressLine}
                            </p>
                          );
                        })}
                        <a href={region.tel}>{region.tel}</a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <h2 className=" font-bold text-2xl">pusat inovasi</h2>
              <div className="grid md:grid-cols-2 sm:grid-col-1 gap-7 pb-10">
                {innovationCenters.map((innovationCenter) => {
                  return (
                    <div className="text-[#333333] text-base mt-4 font-extrabold" key={innovationCenter.id}>
                      <h3>{innovationCenter.country}</h3>
                      <div className="text-[#4d4d4d] text-sm font-normal leading-7">
                        {innovationCenter.addressLines.map((addressLine) => {
                          return <p key={addressLine}>{addressLine}</p>;
                        })}
                        <a href={innovationCenter.tel}>{innovationCenter.tel}</a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            {solutions.map((solution) => {
              return (
                <div className="text-[#333333] text-base mb-1 font-extrabold px-4" key={solution.id}>
                  <h3>{solution.title}</h3>
                  <div className="text-[#4d4d4d] text-sm font-normal">
                    {solution.contents.map((content) => {
                      return (
                        <Link to={content.link} key={content.text}>
                          <p className=" p-1 break-normal leading-6">{content.text}</p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="grid md:grid-cols-2 sm:grid-col-1 justify-between w-2/6">
            {inspirations.map((inspiration) => {
              return (
                <div className="text-[#333333] text-base mb-1 font-extrabold px-4" key={inspiration.id}>
                  <h3>{inspiration.title}</h3>
                  <div className="text-[#4d4d4d] text-sm font-normal break-normal">
                    {inspiration.contents.map((content) => {
                      return (
                        <Link to={content.link} key={content.text}>
                          <p className=" p-1 break-normal leading-6">{content.text}</p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      {getCopyright()}
    </footer>
  );
};

export default Footer;
